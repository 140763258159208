<template>
  <portal to="account-message" >
    <div class="account-message-modal" @click="closeModal"></div>
    <div class="account-message-box">
      <div class="header">
        <img src="@/assets/icons/warn-icon.svg" alt="" />{{ title }}
      </div>
      <div class="content">
        <!-- <p>{{ content }}</p> -->
        <p v-html="content"></p>
        <div v-if="action === 'confirm'" class="btns">
          <div class="btn" @click="closeModal">{{ $t('confirm') }}</div>
        </div>
        <div v-if="action === 'delete'" class="btns">
          <div class="btn cancel" @click="closeModal">{{ $t('cancel') }}</div>
          <div class="btn delete" @click="handleConfirm">{{ $t('confirm') }}</div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  name: 'PortalMessage',
  props: ['title', 'content', 'action'],
  data() {
    return {}
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleConfirm() {
      this.$emit('confirm')
    },
    handleCancel() {
      this.$emit('close')
    },
  }

}
</script>

<style lang="scss" scoped>
.account-message-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 44, 57, 0.6);
  z-index: 3;
}

.account-message-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 640px;
  z-index: 3;
}

.header {
  background: #4a5c78;
  color: #ffffff;
  border-radius: 23px 23px 0px 0px;
  font-size: 23px;
  padding: 15px 32px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 22px;
  }
}

.content {
  background: #282942;
  border-radius: 0px 0px 23px 23px;
  text-align: center;
  position: relative;
  top: -32px;
  padding: 0 24px 50px 24px;

  p::v-deep {
    font-size: 32px;
    line-height: 44px;
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 10px;

    span {
      font-style: italic;
      font-weight: 500;
    }
  }
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  display: inline-block;
  background: #ffc600;
  border-radius: 8px;
  font-size: 24px;
  color: #282942;
  padding: 10px 48px;
  cursor: pointer;
}

.btn:hover {
  background: #ffd231;
}

.cancel {
  border: 1px solid #FFFFFF;
  background: #282942;
  color: #ffffff;
  margin-right: 32px;
}

.cancel:hover {
  background: #3d3f55;
}

.delete {
  border: 1px solid #F94144;
  background: #F94144;
  color: #ffffff;
}

.delete:hover {
  border: 1px solid #F95052;
  background: #F95052;
}

</style>